import React from 'react';
import logo from '../../img/logo-red.png';

function LiveFooter() {
  return (
    <footer className="w-full pt-8 pb-20 z-10">
      <div className="flex flex-col items-center justify-center px-6">
        <a href="/"><img src={logo} alt="Logo" className='h-8' /></a>
        <p className='text-sm text-center'>Kad Kahwin Digital Eksklusif. Siap RSVP, Salam Kaut, Ucapan & Lagu. Paling penting, senang nak guna.</p>
      </div>
    </footer>
  );
}

export default LiveFooter;