import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import UbahTema from '../components/UbahTema';
import Auth from '../components/Auth';
import LogMasuk from '../components/LogMasuk';

const Tema = () => {
  const { id } = useParams();
  const [login, setLogin] = useState(true);
  const [ownerEmail, setOwnerEmail] = useState(true);
  
  return (
    <div>
      <Helmet>
        <title>Ubah Tema | Jemputlah.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Auth id={id} setLogin={setLogin} setOwnerEmail={setOwnerEmail} />
          {login ? 
            (
              <UbahTema />
            ) : ( 
              <div>
                <LogMasuk ownerEmail={ownerEmail} setLogin={setLogin} />
              </div>
            )
          }
    </div>
  )
}

export default Tema