import React, { useState, useEffect } from 'react';
import Themes from '../Themes';
import { FaSpinner } from "react-icons/fa";

function LiveIntro({ playerRef, setPlaying, setHero, setScroll, theme, walimatulurus, H2, fontP, H2size, groomName, brideName }) {
  const Components = Themes[theme];

  const [openModalType, setOpenModalType] = useState('intro');
  const [loading, setLoading] = useState(true);
  const [modalOpacity, setModalOpacity] = useState(1);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  const handlePlayVideo = () => {
    if (playerRef.current) {
      playerRef.current.playVideo();
    }
    setPlaying(true);
  };

  const handleFullscreen = () => {
    const elem = document.documentElement; // This targets the whole page
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { // For Firefox
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { // For Safari
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { // For IE/Edge
      elem.msRequestFullscreen();
    }
  };

  const handleOpen = () => {
    handleFullscreen();
    setModalOpacity(0);
    handlePlayVideo();
    setScroll('auto');
    setTimeout(() => {
      setOpenModalType(null);
      setHero(true);
    }, 2000);
  };

  const center = {
    backgroundImage: `url(${Components.fc})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <div>
      {openModalType === 'intro' && (
        <div
          className={`fixed z-50 top-0 left-0 w-full h-full backdrop-blur-sm flex items-center justify-center overlay ${
            window.innerWidth <= 768 ? 'mobile-sheet' : ''
          }`}
          style={{ opacity: modalOpacity, transition: 'opacity 1s ease-out 1s' }}
        >
          <div
            className={`bg-white py-8 px-3 h-screen w-full flex items-center bg-opacity-20 z-50 ${
            // className={`bg-white py-8 px-3 rounded-full shadow-lg z-50 ${
              window.innerWidth <= 768 ? 'mobile-sheet-content' : ''
            }`}
            style={{ 
              opacity: modalOpacity, 
              transition: 'opacity 1s ease-out 1s'
             }}
          >
            <div 
              className='relative flex flex-col justify-center items-center w-full'
              style={{ opacity: modalOpacity, transition: 'opacity 1s ease-out' }}
            >
              {loading ? (
                <div className="fixed inset-0 flex flex-col items-center justify-center space-y-5">
                  <FaSpinner className="text-4xl text-red-400 animate-spin" />
                </div>
              ) : (
                <div className={`${Components.fcStyle}`} style={center}>
                  <p className={`text-l mb-8 ${fontP} uppercase`}>{walimatulurus}</p>
                  <div>
                    <h2 className={`${H2size} ${H2} capitalize`}>
                      {groomName}
                    </h2>
                    <p className={`text-3xl ${H2} capitalize`}>&</p>
                    <h2 className={`${H2size} ${H2} capitalize`}>
                      {brideName}
                    </h2>
                  </div>
                  <button 
                    onClick={handleOpen}
                    className='rounded-full my-8 bg-red-400 hover:bg-red-500 text-white px-3 py-1'
                  >
                    Buka
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default LiveIntro