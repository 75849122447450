import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaCheck, FaCheckDouble, FaTimes } from "react-icons/fa";
import axios from 'axios';

function UserCards({ setLoading }) {
  const email = localStorage.getItem('userEmail');
  const [cards, setCards] = useState([]);

  useEffect(() => {
    axios.get(`https://api.jemputlah.com/api/senaraikad/${email}`)
      .then(response => {
        const cards = response.data;
        setCards(cards);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [email, setLoading]);

  return (
    <div className="max-w-lg">
      <h2 className="text-2xl font-semibold mb-4">Kad Kahwin Digital Anda</h2>
      <div>
        {cards.map(card => (
          <div key={card.card_id} className="bg-white shadow-md rounded-lg p-4 mb-4">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-semibold mb-2">ID: {card.card_id}</h3>
              <Link to={`/dashboard/${card.card_id}`} className="inline-flex items-center px-4 py-2 bg-red-400 text-white rounded-md hover:bg-red-500">
                Akses Kad
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default UserCards;
