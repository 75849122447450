import React from 'react';
import { motion, useTransform } from 'framer-motion';
import Themes from '../Themes';

function LiveTentative({ scrollYProgress, xTopStyle, xBottomStyle, hero, theme, tentative, fontP }) {
  const opacity = useTransform(scrollYProgress, [0.4, 0.6, 0.9], [0, 1, 0]);

  const translateY = useTransform(scrollYProgress, [0.4, 0.6, 0.9], [50, 0, -50]);

  const Components = Themes[theme];

  // const heroStyle = {
  //   backgroundImage: `url(${Components.bg})`,
  //   backgroundSize: 'cover',
  //   backgroundPosition: 'center',
  // };

  const aturCaraMajlis = JSON.parse(tentative);

  const xTop = useTransform(scrollYProgress, [0.4, 0.6], [xTopStyle, 0]);
  const xBottom = useTransform(scrollYProgress, [0.4, 0.6], [xBottomStyle, 0]);
  const scaleUp = useTransform(scrollYProgress, [0.4, 0.6], [1.2, 1]);

  const ftImg = hero ? { opacity: opacity, translateX: xTop, scale: scaleUp } : {};
  const fbImg = hero ? { opacity: opacity, translateX: xBottom, scale: scaleUp } : {};

  return (
    <div className="min-h-screen relative flex items-center justify-center">
      <motion.div style={{ opacity, translateY }} className="text-center z-10 my-20">
        <div className={Components.tStyle}>
          <h1 className={`text-l mb-8 uppercase font-bold ${fontP}`}>Atur Cara Majlis</h1>
          {aturCaraMajlis.map((item, index) => (
            <div key={index} className={`my-4 ${fontP}`}>
              <p className="text-l">{item.title}:</p>
              <p className="text-l">{item.displaytime}</p>
            </div>
          ))}
        </div>
      </motion.div>
      <motion.img
        src={Components.ft}
        style={ftImg}
        className={Components.ftStyle}
      />
      <motion.img
        src={Components.fb}
        style={fbImg}
        className={Components.fbStyle}
      />
    </div>
  );
}

export default LiveTentative;
