import React, { useState } from "react";
import mbxGeocoding from "@mapbox/mapbox-sdk/services/geocoding"; // Import for Geocoding API

const mapboxToken = "pk.eyJ1Ijoia2hhaWFrbWFsIiwiYSI6ImNtMHVpY3ZjdTBjaDcya3M3MmNlZGVjbGsifQ.zRbYTl7Oy3GBI_DYr7peaQ"; // Replace with your token
const geocodingClient = mbxGeocoding({ accessToken: mapboxToken });

function MapboxSearch() {
    const [query, setQuery] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [userCoordinates, setUserCoordinates] = useState(null);

    const handleSearchChange = async (e) => {
        setQuery(e.target.value);

        // Make API call only if the input length is greater than 2
        if (e.target.value.length > 2) {
            const response = await geocodingClient.forwardGeocode({
                query: e.target.value,
                limit: 5,
                countries: ["MY"], // Restrict results to Malaysia
            }).send();

            if (response && response.body && response.body.features) {
                setSuggestions(response.body.features);
            }
        }
    };

    const handleSelectPlace = (place) => {
        const [lng, lat] = place.geometry.coordinates;
        setSelectedPlace({
            name: place.place_name,
            coordinates: [lng, lat],
        });

        // Set user coordinates to selected place's coordinates
        setUserCoordinates([lng, lat]);
        setSuggestions([]); // Clear suggestions
        setQuery(place.place_name); // Optionally set the query to the selected place name
    };

    // Generate the static map URL
    const getStaticMapUrl = (lng, lat) => {
        return `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l+ff0000(${lng},${lat})/${lng},${lat},14/600x400?access_token=${mapboxToken}`;
    };

    return (
        <div className="relative w-full max-w-lg mx-auto">
            {/* Search input */}
            <input
                type="text"
                value={query}
                onChange={handleSearchChange}
                placeholder="Search for a location..."
                className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />

            {/* Suggestions */}
            {suggestions.length > 0 && (
                <ul className="absolute w-full mt-2 bg-white border border-gray-200 rounded-md shadow-lg max-h-48 overflow-y-auto z-10">
                    {suggestions.map((place, index) => (
                        <li
                            key={index}
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => handleSelectPlace(place)}
                        >
                            {place.place_name}
                        </li>
                    ))}
                </ul>
            )}

            {/* Display static map */}
            {userCoordinates && (
                <div className="mt-6">
                    <img
                        src={getStaticMapUrl(userCoordinates[0], userCoordinates[1])}
                        alt="Selected Location Map"
                        className="w-full h-auto border border-gray-300 rounded-md shadow-sm"
                    />
                </div>
            )}

            {/* Display selected place's coordinates */}
            {selectedPlace && (
                <div className="mt-4 p-4 bg-gray-100 rounded-md">
                    <h4 className="font-bold">Selected Location:</h4>
                    <p>{selectedPlace.name}</p>
                    <p>
                        Coordinates: Latitude {selectedPlace.coordinates[1]}, Longitude{" "}
                        {selectedPlace.coordinates[0]}
                    </p>
                </div>
            )}
        </div>
    );
}

export default MapboxSearch;
