import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import qrcode from '../img/qrcodegc.jpg';
import logo from '../img/logo-white.png';
import { FaPlay, FaPause, FaYoutube } from 'react-icons/fa';
import EnableMusic from './EnableMusic';

const MusicSalesPage = () => {
  const { id } = useParams();
  const promo_end = "2024-02-25";
  const targetDate = new Date(promo_end).getTime();

  // Calculate the remaining time with UTC+8 offset
  const calculateTimeLeft = () => {
    const now = new Date().getTime() + 8 * 60 * 60 * 1000; // UTC+8 offset
    const difference = targetDate - now;

    if (difference <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, []);

  const phoneNumber = '60102701320'; // Replace with the actual WhatsApp number

  const message = encodeURIComponent(`Aktifkan Lagu di Kad Kahwin Digital\nID: ${id}`);
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${message}`;

  return (
    <div>
      <header className="w-full bg-red-400 shadow-md py-4 z-10">
        <div className="flex items-center justify-center px-6">
          <a href="/"><img src={logo} alt="Logo" className='h-8' /></a>
        </div>
      </header>
      <div className="md:p-24 flex items-center justify-center">
        <div className="bg-white px-8 py-12 rounded-md md:shadow-lg max-w-md w-full text-center">
          <EnableMusic />
          {/* <h1 className="text-2xl font-bold mb-4">Aktifkan Lagu</h1>
          <p className="text-gray-700 mb-4">
            Sekarang dah boleh letak lagu dalam kad. :)
          </p>
          <h2 className='text-xl font-bold my-8'>Apa Yang Best?</h2>

          <div className='space-y-4 mb-8'>
            <div className="flex items-center justify-center">
              <div className="mr-4">
                <FaPlay className="text-red-400 text-4xl cursor-pointer" />
              </div>
              <div>
                <FaPause className="text-red-400 text-4xl cursor-pointer" />
              </div>
            </div>
            <p className="text-gray-700">Lagu AutoPlay dengan butang Pause dan Play agar jemputan boleh hentikan lagu jika situasi tidak sesuai.</p>
          </div>

          <div className='space-y-4 mb-8'>
            <div className="flex items-center justify-center">
              <FaYoutube className="text-red-400 text-6xl cursor-pointer" />
            </div>
            <p className="text-gray-700">Boleh pilih lagu dari senarai kami atau masukkan sahaja link YouTube lagu kesukaan anda.</p>
          </div>

          <a
            href="https://jemputlah.com/kad/demoplus"
            target="_blank"
            rel="noreferrer"
            className='block text-center w-full rounded-lg mb-4 text-red-400 hover:text-white font-bold capitalize px-5 py-3 border border-red-400 hover:border-red-500 hover:bg-red-500'
          >
            Demo
          </a>

          <h2 className='text-xl font-bold my-8'>Nak aktifkan lagu?</h2>
          <p className="text-gray-700">Boleh scan QR Code dibawah untuk membuat pembayaran mengikut jumlah berikut:</p>
          <div className='my-8'>
            <h1 className='text-5xl font-bold'>RM10.00</h1>
            <p className="text-gray-700">sahaja</p>
          </div>
          <img src={qrcode} />
          <div className='space-y-8 my-8'>
            <p className="text-gray-700">Selepas membuat pembayaran, boleh hantarkan bukti pembayaran ke WhatsApp kami. Klik butang dibawah:</p>
            <div>
              <a
                href={whatsappLink}
                target="_blank"
                rel="noreferrer"
                className="bg-white text-center text-red-400 hover:bg-red-400 hover:text-white px-5 py-3 rounded w-full border-solid border-2 border-red-400"
              >
                Hantar Bukti Pembayaran
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </div>
    
  );
};

export default MusicSalesPage;
