import React from 'react'
import { gifts } from '../context/GiftList';

function GiftIdeas() {
  return (
    <div className='pt-8 space-y-2'>
      <h2 className='font-bold'>Idea Hadiah Kahwin</h2>
      <div className="flex flex-wrap">
        {gifts.map((gift) => (
          <div key={gift.id} className="w-1/2 p-1">
            <a href={gift.link} target="_blank" rel="noopener noreferrer">
              <div className="max-w-sm rounded overflow-hidden shadow-lg">
                <img 
                  src={gift.image} 
                  alt={gift.title} 
                  className="w-full"
                  loading="lazy"
                />
                <div className="p-4 flex items-center justify-between">
                  <h2 className="text-sm">{gift.title}</h2>
                  {/* <h2 className="text-sm font-bold text-red-400">RM{gift.price}</h2> */}
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export default GiftIdeas