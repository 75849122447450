import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Views from '../components/Views';
import WhatsAppFAB from '../components/WhatsAppFAB';
import BackButton from '../components/BackButton';

function Kad() {
  const { view } = useParams();
  const Components = Views[view];

  return (
    <div>
      <BackButton />
      {/* <WhatsAppFAB /> */}
      <Components.Hero />
      <Components.InviteMessage />
      <Components.Venue />
      <Components.Tentative />
      <Components.Countdown />
      {/* <Components.FAB /> */}
      <Components.BottomBar />
    </div>
  );
}

export default Kad;
