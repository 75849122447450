import React from 'react';
import logo from '../img/logo-white.png';

function NavBar2() {
  return (
    <header className="w-full bg-red-400 shadow-md py-4 md:pt-20 z-10">
      <div className="flex items-center justify-center px-6">
        <a href="/"><img src={logo} alt="Logo" className='h-8' /></a>
      </div>
    </header>
  );
}

export default NavBar2;