import React from 'react'
import { items } from '../context/ItemList';

function Recommended() {
  return (
    <div className='pt-8 space-y-2'>
      <h2 className='font-bold'>Recommended</h2>
      <div className="flex flex-wrap">
        {items.map((item) => (
          <div key={item.id} className="w-full p-2">
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              <div className="max-w-sm rounded overflow-hidden shadow-lg">
                <img 
                  src={item.image} 
                  alt={item.title} 
                  className="w-full"
                  loading="lazy"
                />
                <div className="p-4 flex items-center justify-between">
                  <h2 className="text-sm">{item.title}</h2>
                  <h2 className="text-sm font-bold text-red-400">RM{item.price}</h2>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Recommended