import React, { useState, useEffect } from 'react';
import { FaMapMarkerAlt, FaWaze } from 'react-icons/fa';

function CreateLocation() {
  const [locations, setLocations] = useState([]);
  const [locationName, setLocationName] = useState('');
  const [coordinate, setCoordinate] = useState('');
  const [error, setError] = useState('');

  const embedUrl = `https://www.youtube.com/embed/_v3Ndd6p86g?rel=0&loop=1&playlist=_v3Ndd6p86g`;

  useEffect(() => {
    const storedLocations = localStorage.getItem('directions');
    if (storedLocations) {
      setLocations(JSON.parse(storedLocations));
    }
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Regular expression to match coordinates (latitude,longitude)
    const coordinatePattern = /^-?\d{1,2}\.\d{1,6},\s?-?\d{1,3}\.\d{1,6}$/;

    // Validate coordinate format
    if (!coordinatePattern.test(coordinate)) {
      setError('Masukkan koordinat mengikut format seperti: 3.XXXXXX,101.XXXXXX. Rujuk video diatas cara copy koordinat');
      return;
    }

    // Clear error if valid
    setError('');

    const wazeUrl = `https://waze.com/ul?ll=${coordinate}`;
    const googleMapsUrl = `https://www.google.com/maps?q=${coordinate}`;

    const newLocation = {
      locationName: locationName,
      wazeUrl: wazeUrl,
      googleMapsUrl: googleMapsUrl,
    };

    // Add a new location
    const updatedLocations = [...locations];
    updatedLocations[0] = newLocation;
    setLocations(updatedLocations);
    localStorage.setItem('directions', JSON.stringify(updatedLocations));
    localStorage.setItem('location', locationName);
    window.location.reload();

    // Reset the form data
    setLocationName('');
    setCoordinate('');
  };

  const handleRemoveLocation = () => {
    // Remove the locations
    setLocations([]);
    localStorage.removeItem('directions');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update either locationName or coordinate
    if (name === 'locationName') {
      setLocationName(value);
    } else if (name === 'coordinate') {
      setCoordinate(value);
    }
  };

  return (
    <div className="">
      {/* Existing Locations */}
      {locations.length > 0 && (
        <div className="border p-2 mt-2 mb-4 rounded flex items-center justify-between">
          <p className='text-sm'>{locations[0].locationName}</p>
          <div className="flex space-x-2">
            <div className="flex items-center justify-center w-8 h-8 rounded-full bg-red-500 text-white">
              <FaMapMarkerAlt />
            </div>
            <div className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-300 text-black">
              <FaWaze />
            </div>
            <button
              onClick={handleRemoveLocation}
              className="text-red-700 px-2 py-1"
            >
              X
            </button>
          </div>
        </div>
      )}

      <div className="video-container mb-8">
        <iframe
          width="100%"
          height="500"
          src={embedUrl}
          title="Cara Copy Koordinat"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <div className='mb-8 space-y-4 text-left'>
        <h2 className=''>Step 1:</h2>
        <p className='text-sm'>Klik butang dibawah untuk dapatkan koordinat</p>
        <a 
          target="_blank"
          rel="noopener noreferrer"
          href='https://plus.codes/map'
          className='text-sm bg-red-400 text-white text-center px-4 py-2 rounded hover:bg-red-500 w-full block'>
            KLIK SINI
        </a>
        <p className='text-sm text-red-400'>*Boleh rujuk video diatas cara salin koordinat</p>
      </div>

      {/* Form */}
      <form
        className="flex flex-col text-left space-y-4"
        onSubmit={handleFormSubmit}
      >
        <h2 className=''>Step 2:</h2>
        <label className="mb-2 text-sm">Location Name:</label>
        <input
          type="text"
          name="locationName"
          placeholder="eg: Daun Grand Hall, The Icon, 6th Floor"
          value={locationName}
          onChange={handleInputChange}
          className="mb-4 border p-2 rounded"
          required
        />

        <label className="mb-2 text-sm">Coordinate:</label>
        <input
          type="text"
          name="coordinate"
          placeholder="eg: 3.156525,101.722336"
          value={coordinate}
          onChange={handleInputChange}
          className="mb-4 border p-2 rounded"
          required
        />

        {error && <p className='text-red-400 text-sm'>{error}</p>}

        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-green-500 text-white text-sm px-4 py-2 rounded hover:bg-green-600 w-full"
          >
            {locations.length > 0 ? 'Update Location' : 'Add Location'}
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreateLocation;
