import item1 from '../img/items/item1.jpg';
import item2 from '../img/items/item2.jpg';
import item3 from '../img/items/item3.jpg';
import item4 from '../img/items/item4.jpg';
import item5 from '../img/items/item5.jpg';
import item6 from '../img/items/item6.jpg';
import item7 from '../img/items/item7.jpg';
import item8 from '../img/items/item8.jpg';
import item9 from '../img/items/item9.jpg';
import item10 from '../img/items/item10.jpg';
import item11 from '../img/items/item11.jpg';
import item12 from '../img/items/item12.jpg';
import item13 from '../img/items/item13.jpg';


export const items = [
  {
    id: 1,
    title: 'Kad Kahwin Acrylic',
    image: item1,
    link: 'https://s.shopee.com.my/9A6OxADe2N',
    price: '4.90',
  },
  {
    id: 2,
    title: 'Kad Kahwin Acrylic Frosted',
    image: item2,
    link: 'https://s.shopee.com.my/7ALKZdCk4i',
    price: '5.50',
  },
  {
    id: 3,
    title: 'Kad Kahwin Arch',
    image: item3,
    link: 'https://s.shopee.com.my/7fHbCdxvHd',
    price: '1.35',
  },
  {
    id: 4,
    title: 'Welcome Board Mirror',
    image: item4,
    link: 'https://s.shopee.com.my/2VZV1Fm8kn',
    price: '76.90',
  },
  {
    id: 5,
    title: 'Arch White Welcome Board',
    image: item5,
    link: 'https://s.shopee.com.my/5VD6cIG54T',
    price: '68.65',
  },
  {
    id: 6,
    title: 'Clear Arcrylic Welcome Board',
    image: item6,
    link: 'https://s.shopee.com.my/701uOgk3lY',
    price: '54.97',
  },
  {
    id: 7,
    title: 'Arch Frosted Welcome Board',
    image: item7,
    link: 'https://s.shopee.com.my/3flSQlDHXK',
    price: '68.65',
  },
  {
    id: 8,
    title: 'Arch Black Welcome Board',
    image: item8,
    link: 'https://s.shopee.com.my/8fA8Oiofwb',
    price: '68.65',
  },
  {
    id: 9,
    title: 'Frosted Welcome Board',
    image: item9,
    link: 'https://s.shopee.com.my/9pM5mQGvNl',
    price: '68.65',
  },
  {
    id: 10,
    title: 'Doorgift VIP (Sejadah Mini)',
    image: item10,
    link: 'https://s.shopee.com.my/6KmDat10Bo',
    price: '9.99',
  },
  {
    id: 11,
    title: 'Premium Doorgift Box',
    image: item11,
    link: 'https://s.shopee.com.my/1qJoEMJqwd',
    price: '4.28',
  },
  {
    id: 12,
    title: 'Doorgift VIP (Salt & Pepper)',
    image: item12,
    link: 'https://s.shopee.com.my/1qJoF0Q2NZ',
    price: '4.85',
  },
  {
    id: 13,
    title: 'Mockup Cheque Crystal',
    image: item13,
    link: 'https://s.shopee.com.my/9UjFOSOeXd',
    price: '43.25',
  },
];
