import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa';
import logo from '../img/logo-red.png';

import EditHero from '../components/edit/EditHero';
import EditInviteMessage from '../components/edit/EditInviteMessage';
import EditVenue from '../components/edit/EditVenue';
import EditTentative from '../components/edit/EditTentative';
import EditCountdown from '../components/edit/EditCountdown';
import EditBottomBar from '../components/edit/EditBottomBar';
import { Helmet } from 'react-helmet';
import WhatsAppFAB from '../components/WhatsAppFAB';
import LogMasuk from '../components/LogMasuk';
import Auth from '../components/Auth';
import useBeforeUnload from '../context/UseBeforeUnload';

function Edit() {
  useBeforeUnload('Are you sure you want to leave? Changes you made may not be saved.');

  const [login, setLogin] = useState(true);
  const [ownerEmail, setOwnerEmail] = useState(true);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [updatedWeddingDate, setUpdatedWeddingDate] = useState('');
  const [updatedWalimatulurus, setUpdatedWalimatulurus] = useState('');
  const [updatedStartTime, setUpdatedStartTime] = useState('');
  const [updatedEndTime, setUpdatedEndTime] = useState('');
  const [updatedH2, setUpdatedH2] = useState('');
  const [updatedFontP, setUpdatedFontP] = useState('');

  // console.log('H2: ', updatedH2);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.jemputlah.com/api/kad/${id}`);
        const data = response.data;
  
        // Check if each item exists in localStorage before setting it
        const setLocalStorageItem = (key, value) => {
          // if (!localStorage.getItem(key)) {
          //   localStorage.setItem(key, value);
          // }
          localStorage.setItem(key, value);
        };
  
        setLocalStorageItem('theme', data.theme);
        setLocalStorageItem('walimatulurus', data.walimatulurus);
        setLocalStorageItem('walimatulurus2', data.walimatulurus2);
        setLocalStorageItem('doa', data.doa);
        setLocalStorageItem('toggle_walima', data.toggle_walima);
        setLocalStorageItem('groom_name', data.groom_name);
        setLocalStorageItem('bride_name', data.bride_name);
        setLocalStorageItem('wedding_date', data.wedding_date);
        setLocalStorageItem('parent_name', data.parent_name);
        setLocalStorageItem('message', data.message);
        setLocalStorageItem('groom_fullname', data.groom_fullname);
        setLocalStorageItem('bride_fullname', data.bride_fullname);
        setLocalStorageItem('location', data.location);
        setLocalStorageItem('start_time', data.start_time);
        setLocalStorageItem('end_time', data.end_time);
        setLocalStorageItem('tentative', data.tentative);
        setLocalStorageItem('contacts', data.contacts);
        setLocalStorageItem('directions', data.directions);
        setLocalStorageItem('H2', data.H2);
        setLocalStorageItem('fontP', data.fontP);
        setLocalStorageItem('H2size', data.H2size);
  
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
  
    fetchData();
  }, [id]);

  return (
    <div>
      <Helmet>
        <title>Edit | Jemputlah.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {loading ? (
        <div className="fixed inset-0 flex flex-col items-center justify-center space-y-5">
          <FaSpinner className="text-4xl text-red-400 animate-spin" />
          <img src={logo} alt="Logo" className="h-8 mb-4" />
        </div>
      ) : (
        <div>
          <Auth id={id} setLogin={setLogin} setOwnerEmail={setOwnerEmail} />
          {login ? 
            (
              <div>
                <WhatsAppFAB />
                <EditHero setUpdatedWeddingDate={setUpdatedWeddingDate} setUpdatedWalimatulurus={setUpdatedWalimatulurus} updatedH2={updatedH2} updatedFontP={updatedFontP} />
                <EditInviteMessage updatedWalimatulurus={updatedWalimatulurus} />
                <EditVenue updatedWeddingDate={updatedWeddingDate} setUpdatedStartTime={setUpdatedStartTime} setUpdatedEndTime={setUpdatedEndTime} />
                <EditTentative updatedStartTime={updatedStartTime} updatedEndTime={updatedEndTime} />
                <EditCountdown updatedWeddingDate={updatedWeddingDate} />
                <EditBottomBar setUpdatedH2={setUpdatedH2} setUpdatedFontP={setUpdatedFontP} />
              </div>
            ) : ( 
              <div>
                <LogMasuk ownerEmail={ownerEmail} setLogin={setLogin} />
              </div>
            )
          }
        </div>
      )}
      
    </div>
  );
}

export default Edit;
