import React, { useState, useRef, useEffect } from 'react';
import YouTube from 'react-youtube';
import { FaPlayCircle, FaPauseCircle, FaSpinner } from "react-icons/fa";

const BackgroundMusicSimple = ({ playerRef, setPlaying, isPlaying, videoId, videoStart }) => {

  const onReady = (event) => {
    playerRef.current = event.target;
  };

  const playVideo = () => {
    if (playerRef.current) {
      playerRef.current.playVideo();
    }
    setPlaying(true);
  };

  const pauseVideo = () => {
    if (playerRef.current) {
      playerRef.current.pauseVideo();
    }
    setPlaying(false);
  };

  const opts = {
    width: 1, 
    height: 1,
    playerVars: {
      autoplay: 0,
      start: videoStart,
    },
  };

  return (
    <div className="fixed bottom-20 right-0">
      <YouTube videoId={videoId} opts={opts} onReady={onReady}/>
      {isPlaying ? (
        <div>
          <button 
            onClick={() => pauseVideo()}
            className='py-3 px-5 font-lg text-red-400 hover:text-red-500'>
              <FaPauseCircle className='w-6 h-6' />
          </button>
        </div>
      ) : (
        <div>
          <button 
            onClick={() => playVideo()}
            className='py-3 px-5 font-lg text-red-400 hover:text-red-500'>
              <FaPlayCircle className='w-6 h-6' />
          </button>
        </div>
      )}
    </div>
  );
};

export default BackgroundMusicSimple;
