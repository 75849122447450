import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import logo from '../img/logo-white.png';
import { Helmet } from 'react-helmet';
import { themes } from '../context/ThemesList';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa';
import theme1 from '../img/theme1.png';
import NavBar from '../components/NavBar';

function UbahTema() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [userTheme, setUserTheme] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.jemputlah.com/api/kad/${id}`);
        const data = response.data;

        setUserTheme(data.theme);
        console.log(data.theme);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]); 

  const handlePreview = (theme) => {
    window.open(`/kad/${id}/theme${theme}`, '_blank');
  };
  
  const handleConfirmAndUpdate = async (theme) => {
    const confirmed = window.confirm('Anda pasti nak guna tema ini?');

    const themeName = `theme${theme}`;
    if (confirmed) {
      try {
        const response = await axios.put(`https://api.jemputlah.com/api/update-cards/${id}`, {
          // Pass the necessary data to update (modify based on your API's needs)
          theme: themeName,
        });

        if (response.status === 200) {
          alert('Tema telah dikemaskini');
          window.location.reload(); // Reloads the page
        } else {
          alert('Gagal mengemaskini tema');
        }
      } catch (error) {
        console.error('Error updating the theme:', error);
        alert('Terdapat ralat semasa mengemaskini tema');
      }
    }
  };
  return (
    <div>
      {loading ? ( 
          <div className="fixed inset-0 flex flex-col items-center justify-center space-y-5">
            <FaSpinner className="text-4xl text-red-400 animate-spin" />
            <img src={logo} alt="Logo" className="h-8 mb-4" />
          </div>
        ) : (
        <div>
          <NavBar />
          <div className='pt-20 px-4'>
            <h1 className="text-xl font-bold mb-4">Tema Anda</h1>
            <div className="w-1/2 md:w-1/4 rounded shadow-lg my-4">
              <a
                href={`/kad/${id}`}
                target="_blank">
                  <img 
                    src={require(`../img/${userTheme}.png`)}
                  />
              </a>
            </div>
          </div>
          <div className="py-14 px-4">
            <h1 className="text-xl font-bold">Pilih Tema</h1>
            <div className="flex flex-wrap">
              {themes.map((theme) => (
                <div key={theme.id} className="w-1/2 md:w-1/4 p-2 md:p-4">
                  <div className="max-w-sm rounded overflow-hidden shadow-lg my-4">
                    <img 
                      src={theme.image} 
                      alt={theme.title} 
                      className="w-full"
                      loading="lazy"
                    />
                  </div>
                  <div className='flex items-center justify-center space-x-2'>
                    <button className='text-white text-sm bg-blue-400 hover:bg-blue-500 p-1 w-full rounded-lg' onClick={() => handlePreview(theme.id)}>Preview</button>
                    <button className='text-white text-sm bg-red-400 hover:bg-red-500 p-1 w-full rounded-lg' onClick={() => handleConfirmAndUpdate(theme.id)}>Pilih Ini</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        )
      }
    </div>
  )
}

export default UbahTema