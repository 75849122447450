import React, { useState, useEffect } from 'react';
import { Tooltip } from 'react-tooltip'
import { FaEye, FaEyeSlash } from "react-icons/fa";

function EditInviteMessage({ updatedWalimatulurus }) {

  const localStorageWalimatulurus = localStorage.getItem('walimatulurus2');
  const localStorageWalimaForm = localStorage.getItem('toggle_walima');
  const localStorageParentName = localStorage.getItem('parent_name');
  const localStorageMessage = localStorage.getItem('message');
  const localStorageGroomFullName = localStorage.getItem('groom_fullname');
  const localStorageBrideFullName = localStorage.getItem('bride_fullname');
  const selectedH2 = localStorage.getItem('H2');
  const selectedfontP = localStorage.getItem('fontP');

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true)
  }, []);

  const parentRef = React.useRef(null);
  const messageRef = React.useRef(null);
  const groomRef = React.useRef(null);
  const brideRef = React.useRef(null);

  const walimaState = localStorageWalimaForm ? JSON.parse(localStorageWalimaForm) : true;
  const [walimaForm, setWalimaForm] = useState(walimaState);
  const [walimatulurus, setWalimatulurus] = useState((localStorageWalimatulurus === "null") ? "" : (localStorageWalimatulurus || "Walimatulurus"));
  const [parentName, setParentName] = useState(localStorageParentName);
  const [message, setMessage] = useState(localStorageMessage);
  const [GroomFullName, setGroomFullName] = useState(localStorageGroomFullName);
  const [BrideFullName, setBrideFullName] = useState(localStorageBrideFullName);

  const handleWalimatulurusChange = (event) => {
    const newWalimatulurus = event.target.value;
    setWalimatulurus(newWalimatulurus);
    localStorage.setItem('walimatulurus2', newWalimatulurus);
  };

  const toggleWalima = () => {
    setWalimaForm(!walimaForm);
    localStorage.setItem('toggle_walima', !walimaForm);
  };

  const handleParentNameChange = (event) => {
    const textarea = parentRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;

    const newParentName = event.target.value;
    setParentName(newParentName);
    localStorage.setItem('parent_name', newParentName);
  };

  const handleMessageChange = (event) => {
    const textarea = messageRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
    
    const newMessage = event.target.value;
    setMessage(newMessage);
    localStorage.setItem('message', newMessage);
  };

  const handleGroomFullNameChange = (event) => {
    const textarea = groomRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;

    const newGroomFullName = event.target.value;
    setGroomFullName(newGroomFullName);
    localStorage.setItem('groom_fullname', newGroomFullName);
  };

  const handleBrideFullNameChange = (event) => {
    const textarea = brideRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;

    const newBrideFullName = event.target.value;
    setBrideFullName(newBrideFullName);
    localStorage.setItem('bride_fullname', newBrideFullName);
  };

  const H2 = selectedH2;
  const fontP = selectedfontP;

  return (
    <div className="text-center pt-20 pb-10 md:w-3/5 w-4/5 mx-auto">
      {/* {walimaForm ? (
        <button onClick={() => toggleWalima()} className='mb-4 text-gray-500'>
          <FaEye className="text-xl" />
        </button>
      ) : (
        <button onClick={() => toggleWalima()} className='mb-4 text-gray-500'>
          <FaEyeSlash className="text-xl" />
        </button>
      )} */}

      <input
        type="text"
        value={walimatulurus}
        onChange={handleWalimatulurusChange}
        className={`text-l mb-8 ${fontP} capitalize bg-transparent w-full text-center`}
      />
      
      <textarea
        value={parentName}
        onClick={() => setIsOpen(false)}
        onChange={handleParentNameChange}
        ref={parentRef}
        className={`input-invitemessage text-3xl ${H2} bg-transparent w-full h-32 text-center`}
      />

      <Tooltip anchorSelect=".input-invitemessage" place="top" isOpen={isOpen} variant="error">
        Klik untuk ubah nama waris
      </Tooltip>

      <textarea
        value={message}
        onChange={handleMessageChange}
        ref={messageRef}
        className={`text-l my-8 ${fontP} bg-transparent w-full text-center h-24 md:h-12`}
      />

      <textarea
        value={GroomFullName}
        onChange={handleGroomFullNameChange}
        ref={groomRef}
        rows="1"
        className={`text-3xl ${H2} bg-transparent w-full text-center`}
      />

      <p className={`text-3xl ${H2}`}>&</p>

      <textarea
        value={BrideFullName}
        onClick={() => setIsOpen(false)}
        onChange={handleBrideFullNameChange}
        ref={brideRef}
        className={`input-bride text-3xl ${H2} bg-transparent w-full text-center`}
      />

      <Tooltip anchorSelect=".input-bride" place="bottom" isOpen={isOpen} variant="error">
        Klik untuk ubah nama penuh pengantin
      </Tooltip>
    </div>
  );
}

export default EditInviteMessage;
