import gift1 from '../img/gifts/gift1.jpg';
import gift2 from '../img/gifts/gift2.jpg';
import gift3 from '../img/gifts/gift3.jpg';
import gift4 from '../img/gifts/gift4.jpg';
import gift5 from '../img/gifts/gift5.jpg';
import gift6 from '../img/gifts/gift6.jpg';
import gift7 from '../img/gifts/gift7.jpg';
import gift8 from '../img/gifts/gift8.jpg';
import gift9 from '../img/gifts/gift9.jpg';
import gift10 from '../img/gifts/gift10.jpg';
import gift11 from '../img/gifts/gift11.jpg';
import gift12 from '../img/gifts/gift12.jpg';


export const gifts = [
  {
    id: 1,
    title: 'Coffee Mug Warmer',
    image: gift1,
    link: 'https://s.shopee.com.my/8KXMgdU9dg',
    price: '18.50',
  },
  {
    id: 2,
    title: 'Love Arcrylic Led Lamp',
    image: gift2,
    link: 'https://s.shopee.com.my/9pMATiTha4',
    price: '9.38',
  },
  {
    id: 3,
    title: 'Bouquet Garni Hand & Body Care Gift',
    image: gift3,
    link: 'https://s.shopee.com.my/9zfagmfMRc',
    price: '99.99',
  },
  {
    id: 4,
    title: 'Bath & Body Works Mini Gift Box',
    image: gift4,
    link: 'https://s.shopee.com.my/3VS6x3BZbK',
    price: '50.00',
  },
  {
    id: 5,
    title: 'Bath and Body Works Normal Size Gift Box',
    image: gift5,
    link: 'https://s.shopee.com.my/6ASs86bFGq',
    price: '119.40',
  },
  {
    id: 6,
    title: 'Lovely Lace Air Freshener Gift Box',
    image: gift6,
    link: 'https://s.shopee.com.my/6ASs920kqt',
    price: '75.60',
  },
  {
    id: 7,
    title: 'Bath and Body Works Fragrance Plug (Basic)',
    image: gift7,
    link: 'https://s.shopee.com.my/VoVOBOITJ',
    price: '68.90',
  },
  {
    id: 8,
    title: 'Glass Chinese Tea Pot Set',
    image: gift8,
    link: 'https://s.shopee.com.my/2fszz6jYOL',
    price: '61.50',
  },
  {
    id: 9,
    title: 'Premium Coffee Mug Gift Set',
    image: gift9,
    link: 'https://s.shopee.com.my/40ONZmhcd7',
    price: '34.90',
  },
  {
    id: 10,
    title: 'Jam Dinding Eksklusif',
    image: gift10,
    link: 'https://s.shopee.com.my/10km1gtAs4',
    price: '76.00',
  },
  {
    id: 11,
    title: 'Stainless Steel Electric Kettle',
    image: gift11,
    link: 'https://s.shopee.com.my/7zuWN5WnKA',
    price: '164.45',
  },
  {
    id: 12,
    title: 'Luna Hearty Serveware Set',
    image: gift12,
    link: 'https://s.shopee.com.my/9zfalDQvq2',
    price: '55.99',
  },
];
