import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import EnableMusic from '../components/EnableMusic';
import VideoId from '../components/VideoId';
import logo from '../img/logo-white.png';
import MusicSalesPage from '../components/MusicSalesPage';
import LogMasuk from '../components/LogMasuk';
import Auth from '../components/Auth';
import { FaSpinner } from 'react-icons/fa';
import logo2 from '../img/logo-red.png';
import axios from 'axios';
import AuthNew from '../components/AuthNew';
import LogMasukNew from '../components/LogMasukNew';

function Music() {
  const [login, setLogin] = useState(true);
  const [ownerEmail, setOwnerEmail] = useState('');
  const { id } = useParams();
  const [activated, setActivated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function musicStatus() { 
      axios.get(`https://api.jemputlah.com/api/clients/${id}`)
        .then(response => {
          const music_activation = response.data.music_activation;
          if(music_activation){
            setActivated(JSON.parse(music_activation));
            setLoading(false);
          } else {
            setActivated(false);
            setLoading(false);
          }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }
    
    musicStatus();
  }, [id]);

  return (
    <div>
      <Helmet>
        <title>Background Music | Jemputlah.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {loading ? (
        <div className="fixed inset-0 flex flex-col items-center justify-center space-y-5">
          <FaSpinner className="text-4xl text-red-400 animate-spin" />
          <img src={logo2} alt="Logo" className="h-8 mb-4" />
        </div>
      ):(
      <div>
        <Auth id={id} setLogin={setLogin} setOwnerEmail={setOwnerEmail} />
        {login ? 
          (
            <div>
              {activated ? (
                <>
                  <header className="w-full bg-red-400 shadow-md py-4 z-10">
                    <div className="flex items-center justify-center px-6">
                      <a href="/"><img src={logo} alt="Logo" className='h-8' /></a>
                    </div>
                  </header>
                  <div className="md:p-24 flex items-center justify-center">
                    <div className="bg-white px-8 py-12 rounded-md md:shadow-lg max-w-md w-full text-center">
                      <h1 className="text-2xl font-bold mb-4">Setup Lagu Kad</h1>
                      <div className='space-y-4 mb-6'>
                        <p className="text-gray-700">
                          Anda boleh masukkan apa-apa sahaja lagu yg anda suka di YouTube. 
                          
                        </p>
                        <p className='text-gray-700 font-bold'>
                          Perlu diingatkan tak semua video boleh digunakan.
                        </p>
                        <p className="text-gray-700">
                          Pilih lagu yg boleh dimainkan disini sahaja. :)
                        </p>
                        <p className="text-gray-700">
                          Disarankan untuk pilih lagu dari video lirik atau instrumental.
                        </p>
                      </div>
                      <EnableMusic />
                      <VideoId />
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  <MusicSalesPage />
                </div>
              )}
            </div>
          ) : ( 
            <div>
              <LogMasuk ownerEmail={ownerEmail} setLogin={setLogin} />
            </div>
          )
        }
      </div>)}
    </div>
  );
}

export default Music;
