import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'
import Themes from '../Themes';
import { FaEye, FaEyeSlash } from "react-icons/fa";

function CreateInviteMessage() {
  const { theme } = useParams();
  const Components = Themes[theme];
  const localStorageWalimatulurus = localStorage.getItem('walimatulurus2');
  const localStorageWalimaForm = localStorage.getItem('toggle_walima');
  const localStorageParentName = localStorage.getItem('parent_name');
  const localStorageMessage = localStorage.getItem('message');
  const localStorageGroomFullName = localStorage.getItem('groom_fullname');
  const localStorageBrideFullName = localStorage.getItem('bride_fullname');
  const selectedH2 = localStorage.getItem('H2');
  const selectedfontP = localStorage.getItem('fontP');
  const localStorageTooltipParent = localStorage.getItem('tooltip_parentname');
  const localStorageTooltipBride = localStorage.getItem('tooltip_bride');

  const [isOpenParent, setIsOpenParent] = useState(localStorageTooltipParent ? JSON.parse(localStorageTooltipParent) : true);
  const [isOpenBride, setIsOpenBride] = useState(localStorageTooltipBride ? JSON.parse(localStorageTooltipBride) : true);

  function setTooltipParent() {
    setIsOpenParent(false);
    localStorage.setItem('tooltip_parentname', false);
  }
  function setTooltipBride() {
    setIsOpenBride(false);
    localStorage.setItem('tooltip_bride', false);
  }

  const parentRef = React.useRef(null);
  const messageRef = React.useRef(null);
  const groomRef = React.useRef(null);
  const brideRef = React.useRef(null);

  const walimaState = localStorageWalimaForm ? JSON.parse(localStorageWalimaForm) : true;
  const [walimaForm, setWalimaForm] = useState(walimaState);
  const [walimatulurus, setWalimatulurus] = useState(localStorageWalimatulurus);
  const [parentName, setParentName] = useState(localStorageParentName || "Abdul Razak bin Abdullah\n&\nAminah binti Ali");
  const [message, setMessage] = useState(localStorageMessage || "Dengan penuh kesyukuran, kami menjemput Dato' | Datin | Tuan | Puan | Encik | Cik seisi keluarga hadir ke majlis perkahwinan anakanda kami");
  const [GroomFullName, setGroomFullName] = useState(localStorageGroomFullName || "Muhammad Adam bin Abdul Razak");
  const [BrideFullName, setBrideFullName] = useState(localStorageBrideFullName || "Siti Hawa binti Faizal");

  const handleWalimatulurusChange = (event) => {
    const newWalimatulurus = event.target.value;
    setWalimatulurus(newWalimatulurus);
    localStorage.setItem('walimatulurus2', newWalimatulurus);
  };

  const toggleWalima = () => {
    setWalimaForm(!walimaForm);
    localStorage.setItem('toggle_walima', !walimaForm);
  };

  const handleParentNameChange = (event) => {
    const textarea = parentRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;

    const newParentName = event.target.value;
    setParentName(newParentName);
    localStorage.setItem('parent_name', newParentName);
  };

  const [creatingMessage, setCreatingMessage] = useState(false);

  const handleMessageChange = (event) => {
    const textarea = messageRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
    
    const newMessage = event.target.value;
    setMessage(newMessage);
    localStorage.setItem('message', newMessage);
  };

  const [creatingGroomFullName, setCreatingGroomFullName] = useState(false);

  const handleGroomFullNameChange = (event) => {
    const textarea = groomRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;

    const newGroomFullName = event.target.value;
    setGroomFullName(newGroomFullName);
    localStorage.setItem('groom_fullname', newGroomFullName);
  };

  const [creatingBrideFullName, setCreatingBrideFullName] = useState(false);

  const handleBrideFullNameChange = (event) => {
    const textarea = brideRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;

    const newBrideFullName = event.target.value;
    setBrideFullName(newBrideFullName);
    localStorage.setItem('bride_fullname', newBrideFullName);
  };

  const H2 = (selectedH2 || Components.H2);
  const fontP = (selectedfontP || Components.fontP);

  return (
    <div className="text-center pt-20 pb-10 md:w-3/5 w-4/5 mx-auto">
      {/* {walimaForm ? (
        <button onClick={() => toggleWalima()} className='mb-4 text-gray-500'>
          <FaEye className="text-xl" />
        </button>
      ) : (
        <button onClick={() => toggleWalima()} className='mb-4 text-gray-500'>
          <FaEyeSlash className="text-xl" />
        </button>
      )} */}

      {walimaForm && (
        <input
          type="text"
          value={walimatulurus}
          onChange={handleWalimatulurusChange}
          className={`text-l mb-8 ${fontP} capitalize bg-transparent w-full text-center`}
        />
      )}

      <textarea
        value={parentName}
        onClick={() => setTooltipParent()}
        onChange={handleParentNameChange}
        ref={parentRef}
        className={`input-invitemessage text-3xl ${H2} bg-transparent w-full h-32 text-center`}
      />

      <Tooltip anchorSelect=".input-invitemessage" place="top" isOpen={isOpenParent} variant="error">
        Klik untuk ubah nama waris
      </Tooltip>

      <textarea
        value={message}
        onChange={handleMessageChange}
        ref={messageRef}
        className={`text-l my-8 ${fontP} bg-transparent w-full text-center h-24 md:h-12`}
      />

      <textarea
        value={GroomFullName}
        onChange={handleGroomFullNameChange}
        ref={groomRef}
        rows="1"
        className={`text-3xl ${H2} bg-transparent w-full text-center`}
      />

      <p className={`text-3xl ${H2}`}>&</p>

      <textarea
        value={BrideFullName}
        onClick={() => setTooltipBride()}
        onChange={handleBrideFullNameChange}
        ref={brideRef}
        className={`input-bride text-3xl ${H2} bg-transparent w-full text-center`}
      />

      <Tooltip anchorSelect=".input-bride" place="bottom" isOpen={isOpenBride} variant="error">
        Klik untuk ubah nama penuh pengantin
      </Tooltip>
    </div>
  );
}

export default CreateInviteMessage;
