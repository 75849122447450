import React from 'react';

function TopBanner() {
  // const logo = 'font-playfair-display';
  return (
    <header className="fixed w-full bg-yellow-400 shadow-md py-2 z-10">
      <div className="flex items-center justify-center px-6 space-x-4">
        <p>Web app ini untuk dijual. Berminat boleh mesej saya. :)</p>
        <a 
          href="https://wa.me/60102701320?text=Berminat%20nak%20beli%20jemputlah.com"
          target="_blank"
          className='text-white bg-black hover:bg-yellow-700 px-5 py-3 text-center rounded-lg shadow-lg'
        >
          WhatsApp Akmal
        </a>
      </div>
    </header>
  );
}

export default TopBanner;