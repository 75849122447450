import React from 'react';
import DemoHero from '../components/demo/DemoHero';
import DemoInviteMessage from '../components/demo/DemoInviteMessage';
import DemoVenue from '../components/demo/DemoVenue';
import DemoTentative from '../components/demo/DemoTentative';
import DemoCountdown from '../components/demo/DemoCountdown';
import DemoBottomBar from '../components/demo/DemoBottomBar';
import DemoNavBar from '../components/demo/DemoNavBar';
import { Helmet } from 'react-helmet';
import BackButton from '../components/BackButton';

function Demo() {

  return (
    <div>
      <Helmet>
        <title>Preview | Jemputlah.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <BackButton />
      <DemoNavBar />
      <DemoHero />
      <DemoInviteMessage />
      <DemoVenue />
      <DemoTentative />
      <DemoCountdown />
      <DemoBottomBar />
    </div>
  );
}

export default Demo;
