import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

// Initialize Google Analytics once, globally
ReactGA.initialize("G-0CFP9XFWC0"); // Replace with your Measurement ID

const GAWrapper = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    // Track pageview on route change
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return children; // Render the children (your routes)
};

export default GAWrapper;
