import React from 'react';
import { motion, useTransform } from 'framer-motion';

function LiveVenue({ scrollYProgress, location, startTime, endTime, weddingDate, fontP }) {
  const opacity = useTransform(scrollYProgress, [0.3, 0.4, 0.6], [0, 1, 0]);
  const translateY = useTransform(scrollYProgress, [0.3, 0.4, 0.6], [50, 0, -50]);

  function convertTo12HourFormat(time24) {
    const [hours, minutes] = time24.split(":");
    const period = hours >= 12 ? "pm" : "am";
    const hours12 = hours % 12 || 12; // Convert 0 to 12 for midnight
  
    return `${hours12}:${minutes} ${period}`;
  }

  const startTimeNew = convertTo12HourFormat(startTime);
  const endTimeNew = convertTo12HourFormat(endTime);

  const weddingDateSet = new Date(weddingDate);
  const weddingDay = getDayFromDate(weddingDateSet);

  const formattedDate = weddingDateSet.toLocaleDateString('ms-MY', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  function getDayFromDate(date) {
    const days = ['Ahad', 'Isnin', 'Selasa', 'Rabu', 'Khamis', "Jumaat", 'Sabtu'];
    const dayIndex = date.getDay();
    return days[dayIndex];
  }

  return (
    <motion.div style={{ opacity, translateY }} className="text-center pt-10 pb-20 md:w-3/5 w-4/5 mx-auto border-t border-gray-700 border-dashed">
      <h1 className={`text-l mt-8 mb-4 uppercase font-bold ${fontP}`}>Tempat</h1>
      {location.split('\n').map((text, index) => (
        <p className={`${fontP}`} key={index}>{text}</p>
      ))}

      <h1 className={`text-l mt-8 mb-4 uppercase font-bold ${fontP}`}>Tarikh</h1>
      <p className={`text-l ${fontP}`}>{weddingDay}, {formattedDate}</p>

      <h1 className={`text-l mt-8 mb-4 uppercase font-bold ${fontP}`}>Masa</h1>
      <p className={`text-l ${fontP}`}>{startTimeNew} - {endTimeNew}</p>
    </motion.div>
  );
}

export default LiveVenue;
