import React, { useState } from 'react';
import Themes from '../Themes';

function PreviewVenue() {
  const localStorageTheme = localStorage.getItem('theme');
  const localStorageStartTime = localStorage.getItem('start_time');
  const localStorageEndTime = localStorage.getItem('end_time');
  const localStorageWeddingDate = localStorage.getItem('wedding_date');
  const selectedfontP = localStorage.getItem('fontP');

  const theme = (localStorageTheme || "theme6");
  const Components = Themes[theme];
  
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const startTime = localStorageStartTime || "11:30";
  const endTime = localStorageEndTime || "17:00";

  function convertTo12HourFormat(time24) {
    const [hours, minutes] = time24.split(":");
    const period = hours >= 12 ? "pm" : "am";
    const hours12 = hours % 12 || 12; // Convert 0 to 12 for midnight
  
    return `${hours12}:${minutes} ${period}`;
  }

  const startTimeNew = convertTo12HourFormat(startTime);
  const endTimeNew = convertTo12HourFormat(endTime);

  const weddingDate = localStorageWeddingDate ? new Date(localStorageWeddingDate) : tomorrow;
  const formattedDate = weddingDate.toLocaleDateString('ms-MY', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const weddingDay = getDayFromDate(weddingDate);

  function getDayFromDate(date) {
    const days = ['Ahad', 'Isnin', 'Selasa', 'Rabu', 'Khamis', "Jumaat", 'Sabtu'];
    const dayIndex = date.getDay();
    localStorage.setItem('Day', days[dayIndex]);
    return days[dayIndex];
  }

  const fontP = (selectedfontP || Components.fontP);

  return (
    <div className="text-center">
      <h1 className={`text-l mb-4 uppercase font-bold ${fontP}`}>Tarikh</h1>
      <p className={`text-l ${fontP}`}>{weddingDay}, {formattedDate}</p>

      <h1 className={`text-l mt-8 mb-4 uppercase font-bold ${fontP}`}>Masa</h1>
      <p className={`text-l ${fontP}`}>{startTimeNew} - {endTimeNew}</p>
    </div>
  );
}

export default PreviewVenue;
