import React, { useState, useEffect } from 'react';
import { FaPhone, FaTimes, FaMapMarkerAlt } from 'react-icons/fa';
import { useParams, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'
import CreateFAB from './CreateFAB';
import CreateContacts from './CreateContacts';
import CreateLocation from './CreateLocation';
import CreatePublish from './CreatePublish';
import CreatePurchase from './CreatePurchase';

function CreateBottomBar() {
  const { theme } = useParams();
  const navigate = useNavigate();
  const localStorageTooltip = localStorage.getItem('tooltip_contact');
  const [isOpen, setIsOpen] = useState(localStorageTooltip ? JSON.parse(localStorageTooltip) : true);

  const [openModalType, setOpenModalType] = useState(null);

  const toggleModal = (modalType) => {
    setIsOpen(false);
    localStorage.setItem('tooltip_contact', false);
    setOpenModalType((prevModalType) =>
      prevModalType === modalType ? null : modalType
    );
  };

  const handleCloseModal = () => {
    setOpenModalType(null);
  };

  const handleOverlayClick = (e) => {
    // Check if the click occurred on the overlay (background)
    if (e.target.classList.contains('overlay')) {
      handleCloseModal();
    }
  };

  return (
    <div>
      <div className="fixed bottom-0 left-0 w-full shadow-md py-2 z-30 bg-white/70">
        <div className="flex items-center justify-center px-6 space-x-2">          
          {/* <button
            rel="noopener noreferrer"
            className=" bg-green-600 hover:bg-green-700 focus:bg-green-700 transition duration-300 ease-in-out rounded-full w-12 h-12 flex items-center justify-center shadow-md"
            onClick={() => toggleModal('contacts')}
          >
            <FaPhone className="contact text-white text-xl" />
          </button>

          <Tooltip anchorSelect=".contact" place="top" isOpen={isOpen} variant="warning">
            Klik untuk tambah contact
          </Tooltip> */}

          {/* <button
            rel="noopener noreferrer"
            className="bg-blue-500 hover:bg-blue-600 focus:bg-blue-600 transition duration-300 ease-in-out rounded-full w-12 h-12 flex items-center justify-center shadow-md"
            onClick={() => toggleModal('location')}
          >
            <FaMapMarkerAlt className="text-white text-xl" />
          </button> */}

          <CreateFAB />
          <a
            className={`py-3 px-3 md:px-5 bg-white text-red-500 hover:bg-red-600 hover:text-white rounded-lg border-solid border-2 border-red-400 hover:border-red-600`}
            href={`/demo`}
            // target="_blank"
          >
              Preview
          </a>

          {/* <a 
            className={`py-3 px-3 md:px-5 bg-red-400 text-black hover:bg-red-600 hover:text-white rounded-lg`} 
            // onClick={() => toggleModal('publish')}
            href={`/addcontacts`}
          >
              Next
          </a> */}
        </div>
      </div>

      {openModalType === 'contacts' && (
        <div
          className={`fixed z-20 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-end md:items-center justify-center overlay ${
            window.innerWidth <= 768 ? 'mobile-sheet' : ''
          }`}
          onClick={handleOverlayClick}
        >
          <div
            className={`bg-white py-8 h-auto mb-20 px-3 rounded-lg w-full md:w-1/3 relative ${
              window.innerWidth <= 768 ? 'mobile-sheet-content' : ''
            }`}
          >
            <button
              onClick={handleCloseModal}
              className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <FaTimes />
            </button>
            <CreateContacts />
          </div>
        </div>
      )}

      {openModalType === 'location' && (
        <div
          className={`fixed z-50 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-end md:items-center justify-center overlay ${
            window.innerWidth <= 768 ? 'mobile-sheet' : ''
          }`}
          onClick={handleOverlayClick}
        >
          <div
            className={`bg-white py-8 h-5/6 overflow-auto mb-20 px-3 rounded-lg w-full md:w-1/3 relative ${
              window.innerWidth <= 768 ? 'mobile-sheet-content' : ''
            }`}
          >
            <button
              onClick={handleCloseModal}
              className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <FaTimes />
            </button>
            <CreateLocation />
          </div>
        </div>
      )}

      {openModalType === 'publish' && (
        <div
          className={`fixed z-20 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-end md:items-center justify-center overlay ${
            window.innerWidth <= 768 ? 'mobile-sheet' : ''
          }`}
          onClick={handleOverlayClick}
        >
          <div
            className={`bg-white py-8 h-auto mb-20 px-3 rounded-lg w-full md:w-1/3 relative ${
              window.innerWidth <= 768 ? 'mobile-sheet-content' : ''
            }`}
          >
            <button
              onClick={handleCloseModal}
              className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <FaTimes />
            </button>
            <CreatePublish />
            {/* <CreatePurchase /> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default CreateBottomBar;
