import { useState, useEffect } from 'react';
import React, { useParams } from 'react-router-dom';
import axios from 'axios';

const EnableRSVP = () => {
  const { id } = useParams();
  const [isRsvpEnabled, setIsRsvpEnabled] = useState(true);
  const [emailEnabled, setEmailEnabled] = useState(true);

  useEffect(() => {
    axios.get(`https://api.jemputlah.com/api/clients/${id}`)
        .then(response => {
          console.log(response.data.rsvp);
          const rsvpStatus = JSON.parse(response.data.rsvp);
          setIsRsvpEnabled(rsvpStatus);
          setEmailEnabled(response.data.email_input);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
  }, []);

  const toggleRsvp = () => {
    axios.put(`https://api.jemputlah.com/api/update-rsvp/${id}`, { rsvp: !isRsvpEnabled })
      .then(response => {
          console.log(response.data.message); // Log success message
          setIsRsvpEnabled(!isRsvpEnabled);
          window.location.reload();
      })
      .catch(error => {
          console.error('Error updating rsvp:', error);
      });
  };
  const toggleEmail = () => {
    axios.put(`https://api.jemputlah.com/api/update-emailinput/${id}`, { email_input: !emailEnabled })
      .then(response => {
          console.log(response.data.message); // Log success message
          setEmailEnabled(!emailEnabled);
          window.location.reload();
      })
      .catch(error => {
          console.error('Error updating rsvp:', error);
      });
  };

  return (
    <div>
      <div className="flex items-center my-5">
        <h1 className='text-red-400 font-bold'>Aktifkan RSVP?</h1>
        <input
          type="checkbox"
          checked={isRsvpEnabled}
          onChange={toggleRsvp}
          id="rsvpToggle"
          className="hidden"
        />
        <label
          htmlFor="rsvpToggle"
          className="flex items-center cursor-pointer bg-gray-100 w-14 h-7 rounded-full p-1 border border-gray-300 transition duration-300 ease-in-out ml-auto"
        >
          <div
            className={`${
              isRsvpEnabled ? 'translate-x-7 bg-green-500' : 'translate-x-0 bg-gray-400'
            } w-5 h-5 rounded-full shadow-md transform transition-transform`}
          ></div>
        </label>
      </div>
      <div className="flex items-center my-5">
        <h1 className='text-red-400 font-bold'>Perlukan email tetamu?</h1>
        <input
          type="checkbox"
          checked={emailEnabled}
          onChange={toggleEmail}
          id="emailToggle"
          className="hidden"
        />
        <label
          htmlFor="emailToggle"
          className="flex items-center cursor-pointer bg-gray-100 w-14 h-7 rounded-full p-1 border border-gray-300 transition duration-300 ease-in-out ml-auto"
        >
          <div
            className={`${
              emailEnabled ? 'translate-x-7 bg-green-500' : 'translate-x-0 bg-gray-400'
            } w-5 h-5 rounded-full shadow-md transform transition-transform`}
          ></div>
        </label>
      </div>
    </div>

  );
};

export default EnableRSVP;
