import React from 'react';
import logo from '../img/logo-white.png';
import { Helmet } from 'react-helmet';
import qrcode from '../img/qrcodegc.jpg';

const Sponsor = () => {

  return (
    <div>
      <Helmet>
        <title>Sponsor | Jemputlah.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <header className="w-full bg-red-400 shadow-md py-4 z-10">
        <div className="flex items-center justify-center px-6">
          <a href="/"><img src={logo} alt="Logo" className='h-8' /></a>
        </div>
      </header>
      <div className="md:p-24 flex items-center justify-center">
        <div className="bg-white px-8 py-12 rounded-md md:shadow-lg max-w-md w-full text-center">
          <h1 className="text-2xl font-bold mb-4">Sponsor</h1>
          <div className='mb-10 text-md text-center space-y-4 my-4'>
            <p>Nak perkenalkan produk/servis anda kepada 1,910 orang pengguna Jemputlah.com?</p>
            <p>WhatsApp saya sekarang</p>
          </div>
          <div>
            <a href="https://wa.me/60102701320?text=Sponsor%20Jemputlah" className='text-white font-bold px-5 py-3 bg-red-400 hover:bg-red-500 rounded-full'>KLIK SINI</a>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default Sponsor;
