import React from 'react';
import logo from '../img/logo-white.png';
import { Helmet } from 'react-helmet';
import qrcode from '../img/qrcodegc.jpg';

const Sumbangan = () => {

  return (
    <div>
      <Helmet>
        <title>Sumbangan | Jemputlah.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <header className="w-full bg-red-400 shadow-md py-4 z-10">
        <div className="flex items-center justify-center px-6">
          <a href="/"><img src={logo} alt="Logo" className='h-8' /></a>
        </div>
      </header>
      <div className="md:p-24 flex items-center justify-center">
        <div className="bg-white px-8 py-12 rounded-md md:shadow-lg max-w-md w-full text-center">
          <h1 className="text-2xl font-bold mb-4">Suka apa yg saya usahakan?</h1>
          <div className='text-md text-center space-y-4 my-4'>
            <p>Saya memerlukan dana untuk tambah baik app Kad Kahwin Digital ini.</p>
            <div>
              <p>✅ Tambah tema pink & purple</p>
              <p>✅ Tambah animasi</p>
              <p>✅ Fix error</p>
              <p>✅ Renew Server</p>
              <p>✅ Guna Google Maps API untuk set lokasi</p>
              <p>✅ Provide support via Live Chat</p>
            </div>
            <p>Boleh support saya sikit? Terima kasih. 😊</p>
            <img src={qrcode} />
            <p className='font-bold'>Atau boleh transfer ke:</p>
            <div>
              <p>Maybank</p>
              <p>5625 2650 8232</p>
              <p>Guru Coklat Enterprise</p>
              <p>Ref: Kad Kahwin Digital</p>
            </div>
            <p>Terima kasih. 😊</p>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default Sumbangan;
