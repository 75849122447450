import React from 'react';
import { FaSpinner } from 'react-icons/fa';

function LoadingSpinner() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-70 text-white">
      <FaSpinner className="text-4xl text-red-600 animate-spin" />
    </div>
  );
}

export default LoadingSpinner;
