import React, { useState, useEffect } from 'react';
import { motion, useTransform } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination'; // Import Swiper styles for pagination
import 'swiper/css/autoplay'; // Import Swiper styles for autoplay
import { Pagination, Autoplay } from 'swiper/modules'; // Import Autoplay module
import { useParams } from 'react-router-dom';
import axios from 'axios';

function LiveUcapan({ scrollYProgress, fontP }) {
  const opacity = useTransform(scrollYProgress, [0.8, 1], [0, 1]);
  const translateY = useTransform(scrollYProgress, [0.8, 1], [50, 0]);
  const { id } = useParams();
  const [ucapan, setUcapan] = useState([]);
  const [ucapanExist, setUcapanExist] = useState(false);

  useEffect(() => {
    axios.get(`https://api.jemputlah.com/api/rsvp/${id}`)
      .then(response => {
        const rsvps = response.data;
        setUcapan(rsvps);
        const ucapanFiltered = rsvps.filter(item => item.ucapan !== null);
        if (ucapanFiltered.length > 0) {
          setUcapanExist(true);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [id]);

  // Sorting the ucapanFiltered array by created_at in descending order
  const sortedUcapan = ucapan.filter(item => item.ucapan !== null).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  return (
    <motion.div style={{ opacity, translateY }} className={`py-5 ${fontP} text-center`}>
      <style>
        {`
          .swiper-pagination-bullet {
            background-color: #d53f8c; /* Default bullet color */
            width: 10px; /* Bullet width */
            height: 10px; /* Bullet height */
            border-radius: 50%; /* Make bullets circular */
          }

          .swiper-pagination-bullet-active {
            background-color: #f87171; /* Active bullet color (change to your preferred color) */
          }
        `}
      </style>
      {ucapanExist && (
        <div>
          <h2 className="text-lg uppercase mb-4">Ucapan</h2>
          <div className="md:w-3/5 w-4/5 mx-auto overflow-hidden max-h-96">
            <Swiper
              spaceBetween={30}
              slidesPerView={1}
              pagination={{
                clickable: true,
                dynamicBullets: true, // Optional: makes bullets dynamically adjust based on slides
              }}
              autoplay={{
                delay: 3000, // 5 seconds delay between slides
                disableOnInteraction: false, // Continue autoplay after interactions
              }}
              modules={[Pagination, Autoplay]} // Register the autoplay and pagination modules
              className="swiper-container"
            >
              {sortedUcapan.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="py-8">
                    <p>{item.ucapan}</p>
                    <p className="text-lg font-semibold mt-4">~ {item.name} ~</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}
    </motion.div>
  );
}

export default LiveUcapan;
