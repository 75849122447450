import PreviewHero from './preview/PreviewHero'; // Adjust the import paths
import PreviewInviteMessage from './preview/PreviewInviteMessage';
import PreviewVenue from './preview/PreviewVenue';
import PreviewTentative from './preview/PreviewTentative';
import PreviewCountdown from './preview/PreviewCountdown';
import PreviewBottomBar from './preview/PreviewBottomBar';

import CreateHero from './create/CreateHero'; // Adjust the import paths
import CreateInviteMessage from './create/CreateInviteMessage';
import CreateVenue from './create/CreateVenue';
import CreateTentative from './create/CreateTentative';
import CreateCountdown from './create/CreateCountdown';
import CreateBottomBar from './create/CreateBottomBar';

const Views = {
  preview: {
    Hero: PreviewHero,
    InviteMessage: PreviewInviteMessage,
    Venue: PreviewVenue,
    Tentative: PreviewTentative,
    Countdown: PreviewCountdown,
    BottomBar: PreviewBottomBar,
  },
  create: {
    Hero: CreateHero,
    InviteMessage: CreateInviteMessage,
    Venue: CreateVenue,
    Tentative: CreateTentative,
    Countdown: CreateCountdown,
    BottomBar: CreateBottomBar,
  },
};

export default Views;
