import React from 'react';
import Product from './Product';

const products = [
  {
    id: 1,
    image: 'https://placekitten.com/300/200', // Replace with your product image URL
    title: 'GrabMart',
    description: '5% off GrabMart e-Voucher worth RM15',
  },
  {
    id: 2,
    image: 'https://placekitten.com/300/201', // Replace with your product image URL
    title: 'Product 2',
    description: 'Description for Product 2',
  },
  // Add more products as needed
];

const ProductList = () => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 gap-2 p-4">
      {products.map((product) => (
        <Product
          key={product.id}
          image={product.image}
          title={product.title}
          description={product.description}
        />
      ))}
    </div>
  );
};

export default ProductList;
