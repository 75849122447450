import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa';
import logo2 from '../../../img/logo-red.png';

function RSVPForm() {
  const { id } = useParams();
  const cardId = id;
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [guests, setGuests] = useState('');
  const [attending, setAttending] = useState(true); // Default to "attending"
  const [ucapan, setUcapan] = useState(''); // New state for ucapan
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailEnabled, setEmailEnabled] = useState(1);

  useEffect(() => {
    axios.get(`https://api.jemputlah.com/api/clients/${id}`)
        .then(response => {
          setEmailEnabled(response.data.email_input);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios.post('https://api.jemputlah.com/api/addrsvp', {
        cardId: cardId,
        name: name,
        phoneNumber: phoneNumber,
        email: email,
        guests: parseInt(guests),
        attending: attending,
        ucapan: ucapan, // Include ucapan in the data
        checkedIn: 0,
      })
      .then(response => {
        console.log(response.data.message); // Log success message
        setMessage('RSVP anda telah disahkan!');
        // Reset the form after successful submission
        setName('');
        setPhoneNumber('');
        setEmail('');
        setGuests('');
        setAttending(true);
        setSubmitted(true);
        setUcapan(''); // Reset ucapan field
        setLoading(false);
      })
      .catch(error => {
          console.error('Error submitting RSVP:', error);
          setSubmitted(true);
          setLoading(false);
          setMessage(`Maaf. RSVP tidak berjaya. Boleh cuba sekali lagi`);
          // setMessage(`Error submitting RSVP: ${error.response.data.message || error.toString()}`);
      });
    
    console.log('Form submitted!');
  };

  return (
    <div className=''>
      {loading ? (
        <div className="fixed inset-0 flex flex-col items-center justify-center space-y-5">
          <FaSpinner className="text-4xl text-red-400 animate-spin" />
          <img src={logo2} alt="Logo" className="h-8 mb-4" />
        </div>
      ) : (
        <div>
          {submitted ? 
          (
            <div className="max-w-lg mx-auto p-4">
              <div className="bg-green-200 text-green-800 py-2 px-4 rounded mb-4">
                {message}
              </div>
            </div>
          ) : (
            <div>
              <div className="max-w-lg mx-auto p-4">
                <h2 className="text-2xl font-semibold mb-4">RSVP</h2>
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label className="block text-gray-700">Nama:</label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder='Masukkan nama anda'
                      className="w-full border rounded py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Nombor Telefon:</label>
                    <input
                      type="tel"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      placeholder='Masukkan no telefon anda'
                      className="w-full border rounded py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
                      required
                    />
                  </div>
                  {emailEnabled ? (
                    <div className="mb-4">
                      <label className="block text-gray-700">Email:</label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder='Masukkan email anda'
                        className="w-full border rounded py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
                        required
                      />
                    </div>
                  ):(
                    <></>
                  )}
                  <div className="mb-4">
                    <label className="block text-gray-700">Jumlah Tetamu:</label>
                    <input
                      type="number"
                      value={guests}
                      onChange={(e) => setGuests(e.target.value)}
                      placeholder='Masukkan jumlah tetamu'
                      className="w-full border rounded py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Hadir atau Tidak Hadir?</label>
                    <select
                      value={attending}
                      onChange={(e) => setAttending(e.target.value === 'true')}
                      className="w-full border rounded py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
                      required
                    >
                      <option value={true}>Hadir</option>
                      <option value={false}>Tidak Hadir</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Ucapan (jika ada):</label>
                    <textarea
                      value={ucapan}
                      onChange={(e) => setUcapan(e.target.value)}
                      placeholder='Tulis ucapan anda di sini...'
                      className="w-full border rounded py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
                    />
                  </div>
                  <button
                    type="submit"
                    className="bg-red-400 text-white py-2 px-4 rounded hover:bg-red-500 focus:outline-none focus:ring focus:border-red-300"
                  >
                    Hantar RSVP
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default RSVPForm;
