import React, { useState } from 'react';
import { FaWhatsapp, FaBars, FaAngleRight, FaAngleLeft } from 'react-icons/fa';

const WhatsAppFAB = () => {
  const phoneNumber = '60102701320';
  const waMinimized = localStorage.getItem('wa');
  const waState = waMinimized ? JSON.parse(waMinimized) : false;
  const [minimized, setMinimized] = useState(waState);

  const toggleMinimized = () => {
    setMinimized((prevMinimized) => !prevMinimized);
    localStorage.setItem('wa', !waState);
  };

  return (
    <div className={`fixed z-30 bottom-28 right-0 flex ${minimized ? 'minimized' : ''}`}>
      <button
        className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-1 px-2 rounded-l-full shadow-lg focus:outline-none focus:shadow-outline-gray active:bg-gray-800 flex items-center"
        onClick={toggleMinimized}
      >
        {minimized ? <FaAngleLeft /> : <FaAngleRight />}
      </button>
      <a
        href={`https://wa.me/${phoneNumber}`}
        target="_blank"
        rel="noopener noreferrer"
        className={`bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-2 shadow-lg focus:outline-none focus:shadow-outline-green active:bg-green-800 flex items-center ${minimized ? 'minimized-btn' : ''} ${!minimized ? '' : ''}`}
      >
        <FaWhatsapp className="mr-2" />
        {!minimized ? 'Ada soalan?' : ''}
      </a>
    </div>
  );
};

export default WhatsAppFAB;
